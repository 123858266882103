<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-2" id="kt_wizard_v2"
        data-wizard-state="step-first"
        data-wizard-clickable="true">

          <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x" v-if="!stepStates.step1">
                     <inline-svg src="/media/svg/icons/General/User.svg" />
                  </span>
                   <span class="svg-icon svg-icon-2x svg-icon-success" v-else>
                     <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
                   </span>
                </div>
                <div class="wizard-label">
                  <h3 class="wizard-title">
                    {{ $t("users.heading.base_settings") }}
                  </h3>
                  <div class="wizard-desc">
                    {{ $t("users.subheading.base_settings") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x" v-if="!stepStates.step2">
                     <inline-svg src="/media/svg/icons/General/Lock.svg" />
                  </span>
                   <span class="svg-icon svg-icon-2x svg-icon-success" v-else>
                     <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
                   </span>
                </div>
                <div class="wizard-label">
                  <h3 class="wizard-title">
                    {{ $t("users.heading.login_credentials") }}
                  </h3>
                  <div class="wizard-desc">
                    {{ $t("users.subheading.login_credentials") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="wizard-step" href="#" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x" v-if="!stepStates.step3">
                     <inline-svg src="/media/svg/icons/Communication/Shield-user.svg" />
                  </span>
                   <span class="svg-icon svg-icon-2x svg-icon-success" v-else>
                     <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
                   </span>
                </div>
                <div class="wizard-label">
                  <h3 class="wizard-title">
                    {{ $t("users.heading.permissions") }}
                  </h3>
                  <div class="wizard-desc">
                    {{ $t("users.subheading.permissions") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="wizard-step" href="#" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                   <span class="svg-icon svg-icon-2x" v-if="!stepStates.step4">
                     <inline-svg src="/media/svg/icons/Code/Info-circle.svg" />
                  </span>
                   <span class="svg-icon svg-icon-2x svg-icon-success" v-else>
                     <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
                   </span>
                </div>
                <div class="wizard-label">
                  <h3 class="wizard-title">
                    {{ $t("users.heading.additional_informations") }}
                  </h3>
                  <div class="wizard-desc">
                    {{ $t("users.subheading.additional_informations") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="/media/svg/icons/Communication/Clipboard-check.svg" />
                  </span>
                </div>
                <div class="wizard-label">
                  <h3 class="wizard-title">
                    {{ $t("users.heading.recapitulation") }}
                  </h3>
                  <div class="wizard-desc">
                    {{ $t("users.subheading.recapitulation") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--begin: Wizard Body -->
        <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
          <!--begin: Wizard Form-->
          <div class="row">
            <div class="offset-xxl-1 col-xxl-10">
              <form class="form" id="kt_form">
                <!--begin: Wizard Step 1-->   
                <ValidationObserver ref="step1">
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <h4 class="mb-10 font-weight-bold text-dark">
                    {{ $t("users.title.fill_base_info") }}
                  </h4>

                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>{{ $t("label.degree_before") }}</label>
                        <input
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          name="degree_before"
                          :placeholder="$t('label.degree_before')"
                          v-model="degreeBefore"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>{{ $t("label.degree_after") }}</label>
                        <input
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          name="degree_after"
                          :placeholder="$t('label.degree_after')"
                          v-model="degreeAfter"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>{{ $t("label.name") }}</label>
                        <ValidationProvider rules="required|min:3" v-slot="{ errors }">
                          <input
                            class="form-control form-control-solid form-control-lg"
                            type="text"
                            :placeholder="$t('placeholder.name')"
                            v-model="name"
                          />
                          <span class="text-danger">{{ errors[0] }}</span>  
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>{{ $t("label.surname") }}</label>
                        <ValidationProvider rules="required|min:3" v-slot="{ errors }">
                          <input
                            class="form-control form-control-solid form-control-lg"
                            type="text"
                            :placeholder="$t('placeholder.surname')"
                            v-model="surname"
                          />
                          <span class="text-danger">{{ errors[0] }}</span>  
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <ValidationProvider rules="min:9|max:9|numeric" v-slot="{ errors }">
                        <label>{{ $t("label.phone") }}</label>
                        <div class="input-group input-group-lg input-group-solid">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="la la-phone"></i>
                            </span>
                          </div>
                          <input
                            type="tel"
                            class="form-control form-control-solid form-control-lg"
                            name="phone"
                            :placeholder="$t('placeholder.phone')"
                            v-model="phone"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>  
                        <span class="form-text text-muted">{{ $t("placeholder.phone_format") }}</span>
                        </ValidationProvider> 
                      </div>
                    </div>
                    <div class="col-xl-6">                      
                      <div class="form-group">
                        <ValidationProvider rules="required|email" v-slot="{ errors }">
                        <label>{{ $t("label.email") }}</label>
                        <div class="input-group input-group-lg input-group-solid">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="la la-at"></i>
                            </span>
                          </div>
                          <input
                            type="email"
                            class="form-control form-control-solid form-control-lg"
                            name="email"
                            :placeholder="$t('placeholder.email')"
                            v-model="email"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>  
                        </ValidationProvider> 
                      </div>
                    </div>
                  </div>
                </div>
                </ValidationObserver>
                <!--end: Wizard Step 1-->

                <!--begin: Wizard Step 2-->
                <ValidationObserver ref="step2">
                <div class="pb-5" data-wizard-type="step-content">
                  <h4 class="mb-10 font-weight-bold text-dark">
                    {{ $t("users.title.fill_login_info") }}
                  </h4>
                  
                    <div class="row">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <label>{{ $t("label.username") }}</label>
                        <ValidationProvider rules="required|min:3" v-slot="{ errors }">
                          <input
                            class="form-control form-control-solid form-control-lg"
                            type="text"
                            :placeholder="$t('placeholder.username')"
                            v-model="login"
                          />
                          <span class="text-danger">{{ errors[0] }}</span>  
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-xl-12">
                      <div class="form-group">
                        <label>{{ $t("label.password_new") }}</label>
                        <ValidationProvider rules="min:8|confirmed:confirmation" v-slot="{ errors }">
                        <input
                          type="password"
                          class="form-control form-control-solid form-control-lg"
                          name="password"
                          :placeholder="$t('placeholder.password_new')"
                          v-model="password"
                        />
                        <span class="text-danger">{{ errors[0] }}</span>  
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-xl-12">
                      <div class="form-group">
                        <label>{{ $t("label.password_repeat") }}</label>
                        <ValidationProvider rules="min:8" v-slot="{ errors }" vid="confirmation">
                        <input
                          type="password"
                          class="form-control form-control-solid form-control-lg"
                          name="verify_password"
                          :placeholder="$t('placeholder.password_repeat')"
                          v-model="passwordConfirmed"
                        />
                        <span class="text-danger">{{ errors[0] }}</span>  
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>

                </div>
                </ValidationObserver>
                <!--end: Wizard Step 2-->

                <!--begin: Wizard Step 3-->
                <ValidationObserver ref="step3">
                <div class="pb-5" data-wizard-type="step-content">
                  <h4 class="font-weight-bold text-dark mb-10">
                    {{ $t("users.title.fill_rules_info") }}
                  </h4>

                  <div class="checkbox-list">
                    <b-list-group>
                      <b-list-group-item
                      v-for="(role, index) in roles"
                      :key="index"
                      >
                        <b-form-checkbox :value="role" v-model="selectedRoles">{{ role.name }}</b-form-checkbox>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </div>
                </ValidationObserver>
                <!--end: Wizard Step 3-->

                <!--begin: Wizard Step 4-->
                <ValidationObserver ref="step4">
                <div class="pb-5" data-wizard-type="step-content">
                  <h4 class="mb-10 font-weight-bold text-dark">
                    {{ $t("users.title.fill_additional_info") }}
                  </h4>
                  
                  <div class="form-group">
                    <label>{{ $t("label.sex") }}</label>
                    <vs-select v-model="selectedSex" :options="sex" :clearable="false"/>
                  </div>

                  <div class="form-group">
                    <label>{{ $t("label.position") }}</label>
                    <vs-select v-model="selectedPosition" :options="positions" label="name" v-if="positions" :clearable="false"/>
                  </div>

                  <div class="form-group">
                    <label>{{ $t("label.language") }}</label>
                    <vs-select v-model="selectedLanguage" :options="languages" label="name" v-if="languages" :clearable="false"/>
                  </div>
                </div>
                </ValidationObserver>
                <!--end: Wizard Step 4-->

                <!--begin: Wizard Step 5-->
                <div class="pb-5" data-wizard-type="step-content">
                  <h4 class="mb-10 font-weight-bold text-dark">
                    {{ $t("users.title.fill_data_check") }}
                  </h4>
                  
                  <div class="border-bottom mb-5 pb-5">
                    <div class="font-weight-bold mb-3"><span v-if="degreeBefore">{{ degreeBefore }}</span> <span v-if="name">{{ name }}</span> <span v-if="surname">{{ surname }}</span> <span v-if="degreeAfter">{{ degreeAfter }}</span></div>
                    <div class="line-height-md">
                      <i class="fa fa-user text-dark icon-sm mr-3"></i> {{ login }} <br>
                      <i class="fa fa-phone text-dark icon-sm mr-3"></i> {{ phone }} <br>
                      <i class="fa fa-at text-dark icon-sm mr-3"></i> {{ email }} 
                    </div>
                  </div>
                  <div class="border-bottom mb-5 pb-5 row">
                    <div class="col-4">
                      {{ $t("label.position") }}<br /><span class="font-weight-bold" v-if="selectedPosition">{{ selectedPosition.name }}</span>
                    </div>
                     <div class="col-4">
                      {{ $t("label.sex") }}<br /><span class="font-weight-bold">{{ selectedSex }}</span>
                    </div>
                     <div class="col-4">
                      {{ $t("label.language") }}<br /><span class="font-weight-bold" v-if="selectedLanguage">{{ selectedLanguage.name }}</span>
                    </div>
                  </div>
                  <div>
                    <div class="font-weight-bold mb-3"> {{ $t("users.title.roles_assign") }} </div>
                      <b-badge variant="primary mr-3"
                      v-for="(role, index) in selectedRoles"
                      :key="index">
                      {{ role.name }}
                      </b-badge>
                 </div>
                </div>
                <!--end: Wizard Step 5-->

                <!--begin: Wizard Actions -->
                <div class="d-flex justify-content-between border-top pt-10">
                  <div class="mr-2">
                    <button
                      @click.prevent="clickPrev"
                      class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                      data-wizard-type="action-prev"
                      >
                      {{ $t("button.previous") }}
                    </button>
                  </div>
                  <div>
                    <button
                      @click.prevent="createUser"
                      class="btn btn-success font-weight-bold text-uppercase px-6 py-4"
                      data-wizard-type="action-submit"
                      ref="save_submit"
                    >
                    <span class="svg-icon svg-icon-md svg-icon-white">
                      <inline-svg src="/media/svg/icons/Communication/Add-user.svg" />
                    </span>
                      {{ $t("users.button.create") }}
                    </button>
                    <button
                      @click.prevent="clickNext"
                      class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                      data-wizard-type="action-next"
                    >
                      {{ $t("button.next_step") }}
                    </button>
                  </div>
                </div>
                <!--end: Wizard Actions -->
              </form>
            </div>
            <!--end: Wizard-->
          </div>
        </div>
        <!--end: Wizard Body -->
      </div>
      <!--end: Wizard-->
    </div>
  </div> 
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";
import KTWizard from "@/assets/js/components/wizard";

export default {
  name: "user_create",
  components: {
  },
  data() {
    return {
      stepStates: {
        'step1' : false,
        'step2' : false,
        'step3' : false,
        'step4' : false,
      },

      languages: null,
      positions: null,
      sex: ['MALE','FEMALE'],
      roles: null,

      degreeBefore: null,
      degreeAfter: null,
      name: null,
      surname: null,
      phone: null,
      email: null,

      login: null,
      password: null,
      passwordConfirmed: null,

      selectedRoles: [],
      selectedSex: null,
      selectedPosition: null,
      selectedLanguage: null
    };
  },
  beforeMount() { 
    this.getRoles();
    this.getLanguages();
    this.getPositions();
  },
  metaInfo () { 
      return { title: this.$t("users.meta.title_create")} 
  },
  mounted() {
    this.initWIzard();

    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("users.meta.title_create") }]);
  },
  methods: {
    createUser(){
      // Loading spinner
      const submitButton = this.$refs["save_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.loading = true;
        ApiService.apiPost("/users", 
        { 
          'degree_before': this.degree_before,
          'degree_after': this.degree_after,
          'name': this.name,
          'surname': this.surname,
          'email': this.email,
          'phone': this.phone,

          'login': this.login,
          'password': this.password,
          'password_confirmation': this.passwordConfirmed,

          'roles': this.selectedRoles,

          'sex': this.selectedSex,
          'position': this.selectedPosition,
          'language': this.selectedLanguage
        })
        .then(() => {
            this.loading = false;
            submitButton.classList.remove("spinner","spinner-light","spinner-right");
        }).catch(error => {
            submitButton.classList.remove("spinner","spinner-light","spinner-right");
            checkErrors(error);
       });
    },
    getRoles() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/roles")
        .then((response) => {
          this.roles = response.data.data;
          this.loading = false;
        });
    },
    getLanguages() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/languages")
        .then((response) => {
          this.languages = response.data.data;
          this.loading = false;
        });
    },
    getPositions() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/positions")
        .then((response) => {
          this.positions = response.data.data;
          this.loading = false;
        });
    },
    initWIzard(){
    // Initialize form wizard
      new KTWizard("kt_wizard_v2", {
        startStep: 1, // initial active step number
        clickableSteps: false, // allow step clicking
        navigation: false
      });     
      // Validation before going to next page

    },
    clickNext(){
      const wizard = new KTWizard("kt_wizard_v2", {});
      let currentStep = wizard.getStep();
      let stepName = 'step' + currentStep;

      const form = this.$refs[stepName];
      form.validate().then(success => {
        if (!success) {
            this.stepStates[stepName] =  false;
            this.$toasted.error("Zkontrolujte zadané údaje.");
            return;
        }    
        this.stepStates[stepName] =  true;
        wizard.goNext();
      })
    },
    clickPrev(){
      const wizard = new KTWizard("kt_wizard_v2", {});
      wizard.goPrev(); 
    }
  }
};
</script>